.new-task-toolbar {
    --padding-top: 0px;
    --padding-bottom: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
}

.footer-md.no-bg-footer::before {
    background-image: none;
}

.footer-ios ion-toolbar:first-of-type {
    --border-width: 0px;
}