.task-page-grid {
    height: 100% !important;
    width: 100% !important;
    padding: 0px;
}

.task-page-row {
    height: 100% !important;
}

.task-page-col {
    background-color: var(--ion-color-light);
    padding: 0px;
    /* border-right: 2px solid var(--ion-color-light); */
}

.task-page-col.middle {
    background-color: white;
}